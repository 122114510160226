import React from "react";
import { Link } from "gatsby";
import { Layout } from "../../../components";
import Slider from "react-slick";

const BaneryReklamowe = ({ location: { pathname } }) => {
  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    draggable: true,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Layout
      seo={{
        title: "BannerAds",
        href: pathname,
        lang: "en",
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/oferta/banery-reklamowe/",
      }}
    >
      <section className="container-fluid google_ads_section">
        <div className="text-center">
          <h1>Banner Ads</h1>
        </div>
        <div className="row social_row">
          <div className="col-md-10 m-auto">
            <p className="text-center">
              Banner ads allow recipients to get acquainted with a given brand, build company awareness, and thanks to the remarketing banners we can encourage customers to visit our Client’s website once again. Campaigns of this type are targeted at users who previously browsed the website on the screen of a computer or mobile devices, or searched for a product in an online store. In this case, the banner is an attempt to make another contact with the potential clients and encourage them to complete the transaction or having them reach other important goals set by our Client. However, that is not everything. Banner Ads in various sizes, and in static, or animated versions, can be displayed to users who browse pages filtered based on a specific topic or reach them based on their interests and history of their activities on the Internet.
            </p>
            <p className="text-center">
              It is worth remembering the great potential of banner remarketing - you can remind users about your services for 540 days!
            </p>

            <div className="text-center">
              <img
                className="img-fluid"
                src={require("../../../assets/img/offer/google.png")}
                alt=""
              />
            </div>
          </div>
        </div>
        <h3>How do we configure banner ads?</h3>
        <div className="row boxes_row">
          <div className="col-lg-4 col_1">
            <div className="inner_box">
              <span>1.</span>
              <p>
                First, we work on the advertising slogan. Its keynote must necessarily reflect the value of the product / service.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col_2">
            <div className="inner_box">
              <span>2.</span>
              <p>
                We then prepare all the necessary graphic elements. The most important factors are: proportional arrangement of graphic elements, modern style of website’s visual identification as well as typography in line with art and aesthetic placement of texts.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col_3">
            <div className="inner_box">
              <span>3.</span>
              <p>
                We make sure that all the information displayed to the users is understandable, and that the users seeing the specific banner instantly know what’s actually being advertised. The exception is the image campaign, the key purpose of which is to attract the user's attention to a specific brand, product, or service.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col_4">
            <div className="inner_box">
              <span>4.</span>
              <p>
                We select the appropriate call to action button, such as "Check" or "Buy Now". CTA is designed to encourage users to take specific actions, for example, purchasing a product.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col_5">
            <div className="inner_box">
              <span>5.</span>
              <p>We place the logo of your company on the banners</p>
            </div>
          </div>
        </div>
        <h3>See our works</h3>
        <div className="container-fluid">
          <Slider {...settings} className="banners-grid">
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/aparthotel_boze_narodzenie/index.html"
                width="300"
                height="600"
                title="ApartHotel Boże Narodzenie"
              ></iframe>
            </div>
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/aparthotel_jesien/index.html"
                width="300"
                height="600"
                title="ApartHotel Jesień"
              ></iframe>
            </div>
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/bialaperla/index.html"
                width="300"
                height="600"
                title="Biała Perła"
              ></iframe>
            </div>
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/czarnaperla_rower/index.html"
                width="300"
                height="600"
                title="Czarna Perła"
              ></iframe>
            </div>
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/domki_chorwacja/index.html"
                width="300"
                height="600"
                title="Domki Chorwacja"
              ></iframe>
            </div>
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/fitizzio/index.html"
                width="300"
                height="600"
                title="Fitizzio"
              ></iframe>
            </div>
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/ggp_evolution/index.html"
                width="300"
                height="600"
                title="GGP Evolution"
              ></iframe>
            </div>
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/ibis/index.html"
                width="300"
                height="600"
                title="Ibis"
              ></iframe>
            </div>
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/ibis_mercure/index.html"
                width="300"
                height="600"
                title="Ibis Mercure"
              ></iframe>
            </div>
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/mielnomolo_300x600/index.html"
                width="300"
                height="600"
                title="Mielno Molo"
              ></iframe>
            </div>
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/nma/index.html"
                width="300"
                height="600"
                title="NaprawMiAuto"
              ></iframe>
            </div>
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/szczawnica_stara/index.html"
                width="300"
                height="600"
                title="Szczawnica"
              ></iframe>
            </div>
            <div className="banners-grid-slide__image">
              <iframe
                src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/virego/index.html"
                width="300"
                height="600"
                title="Virego"
              ></iframe>
            </div>
          </Slider>
        </div>
        <div className="baners_wrapper">
          <img
            className="img-fluid"
            src={require("../../../assets/img/offer/remarketing_baners.png")}
            alt=""
          />
        </div>
      </section>

      <section className="container-fluid section_services">
        <div className="text-center">
          <h2>Other services</h2>
        </div>
        <div className="row">
          <div className="col-md-2 box">
            <span>Web Analytics</span>
            <Link to="/en/offer/web-analytics/">See</Link>
          </div>
          <div className="col-md-2 box">
            <span>Social Media</span>
            <Link to="/en/offer/social-media/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>Linkedin Ads</span>
            <Link to="/en/offer/linkedin-ads/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>Facebook Ads</span>
            <Link to="/en/offer/facebook-ads/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>Google Ads</span>
            <Link to="/en/offer/google-ads/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>International Marketing Campaigns</span>
            <Link to="/en/offer/international-campaigns/">see</Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default BaneryReklamowe;
